<template>
  <div>
    <v-card outlined>
      <v-img aspect-ratio="2"
             class="align-end white--text"
             position="center center"
             src="/images/header6.jpg">
      </v-img>
      <v-card-text class="text-h4 d-xs-block primary--text"><strong>SAGASSER</strong><br>Hausmesse 2025</v-card-text>

      <v-card-text>
        <!-- ----------------- -->
        <!-- NOT AUTHENTICATED -->
        <!-- ----------------- -->
        <template v-if="!authenticated">
          <p>{{ $t('pleaseEnterTheInvitationCodeYouWillFindItInYourOfficialInvitationEmail') }}</p>
          <v-text-field class="mb-2"
                        hide-details="auto"
                        outlined
                        v-bind:append-icon="this.error ? 'mdi-alert-circle' : null"
                        v-bind:label="$t('invitationCode')"
                        v-model="invitationCode"
                        v-on:keyup="uppercase"
                        v-mask="'NNNN-NNNN-NNNN-NNNN'" />
          <v-btn color="accent"
                 v-bind:disabled="invitationCode == null || invitationCode.length < 1"
                 v-bind:loading="loading"
                 v-on:click="signIn">
            {{ $t('signIn') }}
          </v-btn>
          <!--<v-btn color="accent"
                 depressed
                 text
                 v-on:click="$emit('forgot')">
            {{ $t('requestInvitationCode') }}
          </v-btn>-->
        </template>

        <!-- ------------- -->
        <!-- AUTHENTICATED -->
        <!-- ------------- -->
        <template v-else>
          <h1 class="text-h5 mb-4">
            {{ $t('welcome', { fullSalutation }) }}
            <v-btn color="accent"
                   text
                   v-on:click="changeCustomer">
              <v-icon left>
                mdi-refresh
              </v-icon>
              {{ $t('changeCustomer') }}
            </v-btn>
          </h1>

          <!-- LIVE -->
          <v-row v-if="isLive">
            <v-col>
              <h1 class="text-h6 mb-2">Jetzt loslegen</h1>
              <v-btn color="accent" class="mr-2 mb-2"
                     depressed
                     v-bind:to="{ name: 'new-products' }">
                <v-icon left>
                  mdi-star
                </v-icon>
                {{ $t('discoverNewProducts') }}
              </v-btn>
              <v-btn color="accent"
                     class="mb-2 mr-2"
                     depressed
                     v-bind:to="{ name: 'suppliers' }">
                <v-icon left>
                  mdi-glass-wine
                </v-icon>
                {{ $t('ourSuppliers') }}
              </v-btn>
              <v-btn color="accent"
                     class="mb-2"
                     depressed
                     href="https://portal.sagasser.de/booking/public/categories/Hausmesse/Allgemein" target="_blank">
                <v-icon left>
                  mdi-calendar
                </v-icon>
                {{ $t('ourEvents') }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- NOT LIVE -->
          <!--<v-row v-else>
            <v-col>
          <v-chip class="mb-4"
                  color="primary"
                  small
                  text-color="white">
            {{ event.startDate | formatDate('LLL') }}
              </v-chip>
            <div><countdown v-bind:date="event.startDate" /></div>
            <div class="mt-4">
              <p>Am <strong>{{ event.startDate | formatDate('LLL') }}</strong> finden Sie unter dieser Adresse unsere Bestell-Web-App, mit deren Hilfe Sie sowohl vom PC, vom Tablet als auch von Ihrem Smartphone aus Ihre Bestellmengen komfortabel erfassen, korrigieren und später ausdrucken können. Wir freuen uns auf Ihre Teilnahme und hoffen, Ihnen so einen komfortablen und einfachen Bestellvorgang zu ermöglichen!</p>
            </div>
          </v-col>
          </v-row>-->
          <!--<v-row v-else>
            <v-col>
              <div class="mt-4">
                <p>Unsere Hausmesse ist beendet. Vielen Dank für Ihre Teilnahme.</p>
              </div>
            </v-col>
          </v-row>-->

          <!-- BOTH CASES -->
          <!----><v-row>
            <v-col>
              <h1 class="text-h6 mb-2">Vorabinformationen</h1>
              <v-btn class="mr-2 mb-2"
                     color="accent"
                     depressed
                     href="/documents/NeueArtikelMehrweg-2025-5VH34YHK86QV.pdf"
                     target="_blank">
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                Neue Artikel (Mehrweg)
              </v-btn>
              <v-btn class="mb-2"
                     color="accent"
                     depressed
                     href="/documents/NeueArtikelWSS-2025-8MSWCA4JEM6T.pdf"
                     target="_blank">
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                  Neue Artikel (WSS)
              </v-btn>
              <br>
              <v-btn class="mr-2 mb-2"
                     color="accent"
                     depressed
                     href="/documents/KonditionenZugabenMehrweg-2025-R1JMVSVGTBOU.pdf"
                     target="_blank">
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                Konditionen &amp; Zugaben (Mehrweg)
              </v-btn>
              <v-btn class="mb-2"
                     color="accent"
                     depressed
                     href="/documents/KonditionenZugabenWSS-2025-K0PIFKRS02SF.pdf"
                     target="_blank">
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                Konditionen &amp; Zugaben (WSS)
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h1 class="text-h6 mb-2">Vor Ort</h1>
              <v-btn class="mr-2 mb-2"
                     color="accent"
                     depressed
                     href="/documents/Lageplan-2025-M5JOVG77A68S.pdf"
                     target="_blank">
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                Lageplan
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

    </v-card>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Countdown from '@/components/common/Countdown'

  export default {
    components: {
      Countdown,
    },
    computed: {
      authenticated() {
        return this.currentCustomer != null
      },
      companyName() {
        if (this.currentCustomer == null) {
          return null
        }

        return this.currentCustomer.companyName
      },
      fullSalutation() {
        if (this.currentCustomer == null) {
          return null
        }

        if (this.currentCustomer.salutation == null || this.currentCustomer.givenName == null || this.currentCustomer.familyName == null) {
          return `${this.currentCustomer.id} ${this.currentCustomer.companyName}`
        }

        const salutation = this.currentCustomer.salutation.trim()

        if (salutation === 'Herr') {
          return `Herr ${this.currentCustomer.familyName} (${this.currentCustomer.id} ${this.currentCustomer.companyName})`
        } else if (salutation === 'Frau') {
          return `Frau ${this.currentCustomer.familyName} (${this.currentCustomer.id} ${this.currentCustomer.companyName})`
        } else if (salutation === 'Familie') {
          return `Familie ${this.currentCustomer.familyName} (${this.currentCustomer.id} ${this.currentCustomer.companyName})`
        } else {
          return `${this.currentCustomer.givenName} ${this.currentCustomer.familyName} (${this.currentCustomer.id} ${this.currentCustomer.companyName})`
        }
      },
      ...mapGetters([
        'currentCustomer',
        'isLive'
      ])
    },
    data: () => ({
      invitationCode: null
    }),
    methods: {
      uppercase() {
        if (this.invitationCode == null) {
          return
        }

        this.invitationCode = this.invitationCode.toUpperCase()
      },
      async changeCustomer() {
        this.$emit('signOutCustomer')
      },
      async signIn() {
        this.$emit('signInCustomer', this.invitationCode)
      }
    },
    name: 'StartingSoonCard',
    props: {
      event: Object,
      error: Boolean,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "requestInvitationCode": "Einladungscode anfordern",
      "discoverNewProducts": "Neuigkeiten entdecken",
      "ourSuppliers": "Unsere Lieferanten",
      "changeCustomer": "Kunde wechseln",
      "invitationCode": "Einladungscode",
      "pleaseEnterTheInvitationCodeYouWillFindItInYourOfficialInvitationEmail": "Bitte geben Sie den Einladungscode ein. Diesen finden Sie in Ihrer offiziellen Einladungs-E-Mail.",
      "signIn": "Anmelden",
      "welcome": "Willkommen, {fullSalutation}!",
      "viewOrders": "Bestellungen ansehen",
      "ourEvents": "Unsere Veranstaltungen"
    },
    "en": {
      "requestInvitationCode": "Request invitation code",
      "discoverNewProducts": "Discover new products",
      "ourSuppliers": "Our suppliers",
      "changeCustomer": "Change customer",
      "invitationCode": "Invitation code",
      "pleaseEnterTheInvitationCodeYouWillFindItInYourOfficialInvitationEmail": "Please enter the invitation code. You will find it in your official invitation email.",
      "signIn": "Sign in",
      "welcome": "Welcome, {fullSalutation}!",
      "viewOrders": "View orders",
      "ourEvents": "Our events"
    }
  }
</i18n>