<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('supplier') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:disabled="editMode"
                        v-bind:label="$t('supplierNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.id" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('companyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.companyName" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('friendlyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.supplierGroup" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('salutation')"
                        v-model="supplier.salutation" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-model="supplier.givenName" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('familyName')"
                        v-model="supplier.familyName" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('streetAddress')"
                        v-model="supplier.streetAddress" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('postalCode')"
                        v-model="supplier.postalCode" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('city')"
                        v-model="supplier.city" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.email" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('web')"
                        v-model="supplier.web" />
          <!--<v-textarea hide-details="auto"
                      label="Description"
                      outlined
                      v-model="supplier.description" />-->
          <h2 class="mb-2 subtitle-1">{{ $t('delivery') }}</h2>

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('deliveryPeriod0Tag')"
                        v-model="supplier.deliveryPeriod0Tag" />

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('deliveryPeriod1Tag')"
                        v-model="supplier.deliveryPeriod1Tag" />

          <h2 class="mb-2 subtitle-1">{{ $t('registrationDetails') }}</h2>

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('countBoothStaff')"
                        v-model="supplier.countBoothStaff" />

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('countTables')"
                        v-model="supplier.countTables" />

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('countRegularOutlets')"
                        v-model="supplier.countRegularOutlets" />

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('countSpecialOutlets')"
                        v-model="supplier.countSpecialOutlets" />

          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('countDinnerAttendees')"
                        v-model="supplier.countDinnerAttendees" />

          <v-textarea hide-details="auto"
                      outlined
                      v-bind:label="$t('comments')"
                      v-model="supplier.comments" />
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'SupplierEditCard',
    props: {
      editMode: Boolean,
      supplier: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "city": "Stadt",
      "companyName": "Firmenname",
      "email": "E-Mail-Adresse",
      "givenName": "Vorname",
      "familyName": "Nachname",
      "friendlyName": "Anzeigename",
      "postalCode": "Postleitzahl",
      "streetAddress": "Adresse",
      "salutation": "Anrede",
      "supplier": "Lieferant",
      "supplierNumber": "Lieferantennummer",
      "web": "Website",
      "registrationDetails": "Anmeldeinformationen",
      "countBoothStaff": "Anzahl der Standmitarbeiter",
      "countTables": "Menge Biertischgarnituren",
      "countRegularOutlets": "Menge an Steckdosen Haushaltsstrom",
      "countSpecialOutlets": "Menge an Steckdosen Starkstrom",
      "countDinnerAttendees": "Teilnahme am gemeinsamen Abendessen/Personenanzahl",
      "comments": "Weitere Anmerkungen",
      "delivery": "Lieferung",
      "deliveryPeriod0Tag": "Lieferzeitraum 1",
      "deliveryPeriod1Tag": "Lieferzeitraum 2"
    },
    "en": {
      "city": "City",
      "companyName": "Company name",
      "email": "Email",
      "givenName": "Given name",
      "familyName": "Family name",
      "friendlyName": "Anzeigename",
      "postalCode": "Postal code",
      "streetAddress": "Street address",
      "salutation": "Salutation",
      "supplier": "Supplier",
      "supplierNumber": "Supplier number",
      "web": "Web",
      "registrationDetails": "Registration details",
      "countBoothStaff": "Count booth staff",
      "countTables": "Count tables",
      "countRegularOutlets": "Count regular outlets",
      "countSpecialOutlets": "Count special outlets",
      "countDinnerAttendees": "Count dinner attendees",
      "comments": "Comments",
      "delivery": "Delivery",
      "deliveryPeriod0Tag": "Delivery period 1",
      "deliveryPeriod1Tag": "Delivery period 2"
    }
  }
</i18n>