<template>
  <div>
    <top-header v-bind:title="$t('suppliers')">
      <v-menu slot="right-button">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item v-on:click="inviteAllSuppliers">
            <v-list-item-title>{{ $t('inviteAll') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendSupplierProductImages">
            <v-list-item-title>{{ $t('sendProductImagesMail') }}</v-list-item-title>
          </v-list-item><!--
          <v-list-item v-on:click="remindAllSuppliers">
            <v-list-item-title>{{ $t('sendProductImagesReminder') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendEndReminder">
            <v-list-item-title>{{ $t('sendEndReminder') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendStartInfo">
            <v-list-item-title>{{ $t('sendStartInfo') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendPhoto">
            <v-list-item-title>{{ $t('sendPhoto') }}</v-list-item-title>
          </v-list-item>-->
          <v-list-item disabled
                       v-on:click="">
            <v-list-item-title>{{ $t('import') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-on:click="exportSuppliers">
            <v-list-item-title>{{ $t('export') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col>
          <supplier-list v-bind:suppliers="suppliers"
                         v-on:invite-supplier="inviteSupplier"
                         v-on:remove-supplier="deleteSupplier" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import SupplierList from '@/components/administration/SupplierList'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      SupplierList,
      TopHeader
    },
    computed: {
      ...mapGetters([ 'suppliers' ])
    },
    async created() {
      this.loading = true
      await this.$store.dispatch('loadSuppliers')
      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      exportSuppliers() {
        let csv = '"Id","CompanyName","GivenName","FamilyName","StreetAddress","PostalCode","City","Email","Web","State","CountBoothStaff","CountTables","CountRegularOutlets","CountSpecialOutlets","CountDinnerAttendees","Comments"\n'

        for (const supplier of this.suppliers) {
          csv += `"${supplier.id}","${supplier.companyName}","${supplier.givenName}","${supplier.familyName}","${supplier.streetAddress}","${supplier.postalCode}","${supplier.city}","${supplier.email}","${supplier.web}","${supplier.state}","${supplier.countBoothStaff}","${supplier.countTables}","${supplier.countRegularOutlets}","${supplier.countSpecialOutlets}","${supplier.countDinnerAttendees}","${supplier.comments}"\n`
        }

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)
      },
      ...mapActions([
        'deleteSupplier',
        'inviteAllSuppliers',
        'inviteSupplier',
        'sendEmailTemplateToAllSuppliers'
      ]),
      sendStartInfo() {
        this.sendEmailTemplateToAllSuppliers({ filter: s => s.state === 'accepted', templateName: 'SupplierStart' })
      },
      sendPhoto() {
        this.sendEmailTemplateToAllSuppliers({ filter: s => s.state === 'accepted', templateName: 'SupplierPhoto' })
      },
      sendEndReminder() {
        this.sendEmailTemplateToAllSuppliers({ filter: s => true, templateName: 'SupplierEndMessage' })
      },
      sendSupplierProductImages() {
        this.sendEmailTemplateToAllSuppliers({ filter: s => s.state === 'accepted', templateName: 'SupplierProductImages' })
      },
      remindAllSuppliers() {
        this.sendEmailTemplateToAllSuppliers({ filter: s => s.state === 'accepted' && !(s.logoId != null || s.backgroundId != null || s.description != null), templateName: 'SupplierReminder' })
      }
    },
    name: 'Suppliers'
  }
</script>

<i18n>
  {
    "de": {
      "export": "Exportieren…",
      "import": "Importieren…",
      "inviteAll": "Alle einladen",
      "suppliers": "Lieferanten",
      "remindAll": "Alle erinnern",
      "sendProductImagesMail": "Design-Information senden",
      "sendStartInfo": "Startinformation an Alle senden",
      "sendPhoto": "Foto senden",
      "sendEndReminder": "Enderinnerung an Alle senden",
      "sendProductImagesReminder": "Design-Erinnerung an Alle senden"
    },
    "en": {
      "export": "Export…",
      "import": "Import…",
      "inviteAll": "Invite all",
      "suppliers": "Suppliers",
      "remindAll": "Remind all",
      "sendPhoto": "Send photo",
      "sendProductImagesMail": "Send product images mail",
      "sendStartInfo": "Send start info",
      "sendEndReminder": "Send end reminder",
      "sendProductImagesReminder": "Send product images reminder"
    }
  }
</i18n>